.App {
  text-align: center;
}
html {
  background-color: white;
}

header {
  width: 100%;
  color: black;
  padding: top 7px;
}

h1 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
  font-size: 50px;
  padding-left: 30px;
}

section.profile {
  text-align: center;
}

li {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  list-style: none;
}

h2 {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
}
.section{
  color: black;
  padding-left: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
}

.skills{
  color: black;
  padding-left: 40px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
}

.skills2{
  color: black;
  padding-left: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
}

h3 {
  color: black;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 30px;
}

.description p {
  max-width: fit-content;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-weight: bold;
  font-size: 24px;
  width: inline-block;
  text-align: left;
	margin: 0 auto;
}

.description{
  text-align: center;
  max-width:470px;
  margin: 0 auto;
}

footer {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: lighter;
  text-align: left;
}



canvas {
  width: 100%;    
  height: 450px;     
  border-radius: 20px;
}



a {
  color: rgb(88, 88, 88);
  text-decoration: underline;
  position: relative;
  transition: color 0.3s ease;
}

a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: darkgray;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

a:hover {
  color: darkgray;
}

a:hover::after {
  width: 100%;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner::after {
  content: '';
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.responsive-iframe {
  position: relative;
  width: 100%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	right: 0;
	width: 100% !important;
	height: 100% !important;
}

.slideshow-container {
  text-align: center;
  position: relative;
}

.slideshow {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.slide {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
  display: block;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.slide.active{
  opacity: 1;
}

.artworksimg {
  max-width: 100%;
  height: auto;
  width: 700px;
  border-radius: 10px;
}

.artworksimg-bitshape{
  max-width: 100%;
  height: auto;
  width: 400px;
  border-radius: 10px;
}
.artworksimg-technoandon{
  max-width: 100%;
  height: auto;
  width: 600px;
  border-radius: 10px;
}
.artworkstitle {
  font-size: 40px;
  margin-top: 450px;
  max-width: initial;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}
.title {
  font-size: 45px;
  margin-bottom: 50px;
  max-width: initial;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}
.artworksdetail {
  max-width: 700px;
  font-size: 25px;
  margin-top: 5px;
  width: inline-block;
  text-align: left;
	margin: 0 auto;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-weight: 200;
}

.artworksdetailen {
  max-width: 700px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  font-size: 25px;
  margin-top: 5px;
  width: inline-block;
  text-align: left;
	margin: 0 auto;
}

.artworkscontainer{
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.link{
  text-decoration: none;
  color:black;
}

.credit{
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  color: white;
  text-align: left;
}
.bitshape{
  height:  700px;
  width: 100%;
  border-radius: 10px;}

@media screen and (max-width: 480px) {
  /* a {
    transition: color 0.3s ease;
    text-decoration:underline ;
  }

  a::after {
    content: none;
  }

  a:hover {
    color: darkgray;
  } */
  canvas {
    width: 100%;    
    height: 350px; 
  }
  
  .description p {
    font-family: "IBM Plex Sans JP", sans-serif;
    font-weight: bold;
    font-size: 23px;
    width: inline-block;
    text-align: left;
    margin: 0 auto;
  }
  .description{
    text-align: center;
    max-width:300px;
    margin: 0 auto;
  }
  li {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    list-style: none;
  }
  .artworkscontainer{
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
  }
  .artworksdetail {
    max-width: 320px;
    font-size: 20px;
    margin-top: 5px;
    width: inline-block;
    text-align: left;
    margin: 0 auto;
    font-family: "IBM Plex Sans JP", sans-serif;
}
.artworksdetailen {
  max-width: 320px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  font-size: 20px;
  margin-top: 5px;
  width: inline-block;
  text-align: left;
	margin: 0 auto;
}
.artworkstitle {
  font-size: 30px;
  max-width: initial;
  margin-top: 250px; /* 余白を追加 */
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}
.title {
  font-size: 30px;
  max-width: initial;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
}
.responsive-iframe {
  position: relative;
  width: 100%;
  height: 150%;
}
.artworksimg-bitshape{
  max-width: 100%;
  height: auto;
  width: 230px;
  border-radius: 10px;
}
.artworksimg-technoandon{
  max-width: 100%;
  height: auto;
  width: 300px;
  border-radius: 10px;
}
.bitshape{
  max-width: 100%;
  height: 400px;
  width: 400x;
  border-radius: 10px;}

}
